<template>
  <v-form
    ref="form"
    lazy-validation
  >
    <v-card>
      <v-card-title v-if="title">
        <span class="text-h3">{{ $t(title) }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.name"
                dense
                :disabled="readOnly"
                :label="$t('name_*')"
                :rules="[rules.required]"
              />
            </v-col>

            <v-col
              v-if="authUser.isAdmin"
              cols="6"
              sm="6"
            >
              <v-autocomplete
                v-model="options.tenant_id"
                dense
                :loading="tenantLoading"
                :items="tenantOEM"
                :disabled="type == 'edit'"
                item-text="name"
                item-value="id"
                :label="$t('select_tenant_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <v-select
                v-model="options.description"
                dense
                :disabled="readOnly"
                :items="descOptions"
                item-value="id"
                item-text="name"
                :label="$t('description_*')"
                :rules="[rules.required]"
                @change="onDescChange"
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <v-select
                v-model="options.currency"
                dense
                :disabled="readOnly"
                :items="currencyOptions"
                item-value="id"
                item-text="name"
                :label="$t('currency_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <v-select
                v-model="options.status"
                dense
                :disabled="readOnly"
                :items="statusOptions"
                item-value="id"
                item-text="name"
                :label="$t('status_*')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="text-h3">
                {{ $t("price_components") }}
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <v-select
                v-model="options.duration"
                dense
                :disabled="readOnly || options.description == 'kw/h'"
                :items="durationOptions"
                item-value="id"
                item-text="name"
                :label="
                  options.description == 'kw/h'
                    ? $t('duration')
                    : $t('duration_*')
                "
              />
              <!-- :rules="[options.description !== 'kw/h' ? rules.required : '']" -->
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                v-model="options.tax"
                dense
                :disabled="readOnly"
                :label="$t('tax_*')"
                :rules="[rules.required, rules.float(2)]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                v-model="options.price"
                dense
                :disabled="readOnly"
                :label="$t('price_*')"
                :rules="[rules.required, rules.float(2)]"
              />
            </v-col>
            <!--  -->
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <small v-if="!readOnly">{{ $t("indicates_required_field") }}</small>
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="closeForm()"
        >
          Close
        </v-btn>
        <v-btn
          v-if="!readOnly"
          color="primary"
          text
          :loading="loading"
          @click="saveItem"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";

export default {
  mixins: [validationRules],
  props: {
    type: {
      type: String,
      default() {
        return {
          time: null,
        };
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    readOnly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      options: {},
      //
      isOpenTime: false,
      isCloseTime: false,
      //
      descOptions: [
        {
          id: "kw/h",
          name: "KW/H",
        },
        {
          id: "price/time",
          name: "PRICE/TIME",
        },
      ],
      statusOptions: [
        {
          id: "1",
          name: "active",
        },
        {
          id: "0",
          name: "inactive",
        },
      ],

      durationOptions: [
        {
          id: 15,
          name: "15-MINUTES",
        },
        {
          id: 30,
          name: "30-MINUTES",
        },
        {
          id: 45,
          name: "45-MINUTES",
        },
        {
          id: 60,
          name: "60-MINUTES",
        },
      ],
      currencyOptions: [
        {
          id: "PKR",
          name: "PKR (Rs)",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      tenantLoading: "tenantsManagement/getIsLoading",
      tenantsList: "tenantsManagement/getTenantsList",
      //
    }),
    tenantOEM() {
      return this.tenantsList.filter((r) => r.product == "oem");
    },
    title() {
      if (this.readOnly) {
        return "view";
      }
      if (this.type == "create") {
        return "add_new";
      } else if (this.type == "edit") {
        return "edit";
      } else {
        return "";
      }
    },
  },
  watch: {},
  async mounted() {
    if (!this.authUser.isAdmin) {
      this.options.tenant_id = this.authUser.tenant_id;
    }
    if (this.type == "create") {
      this.options.currency = "PKR";
    }

    if (this.type == "edit") {
      this.options = { ...this.item };
    }
  },
  methods: {
    saveItem() {
      if (this.$refs.form.validate()) {
        this.$emit("onsave", this.options);
      }
    },
    closeForm() {
      this.$refs.form.reset();
      this.$emit("onclose");
    },

    //
    onDescChange(v) {
      if (v == "kw/h") {
        delete this.options.duration;
      }
    },
  },
};
</script>
